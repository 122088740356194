export interface SvgIconProps {
  status?: 'in-progress' | 'not-started'
}

export function ProgressCircle({ status }: SvgIconProps) {
  const color = status === 'in-progress' ? 'orange' : ''
  return (
    <svg
      width="60"
      height="60"
      viewBox="-6.2 -6.2 62.5 62.5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="progressCircle"
    >
      <circle
        r="15"
        cx="25"
        cy="25"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeDashoffset="0px"
        fill="transparent"
      ></circle>
    </svg>
  )
}
