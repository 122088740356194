const getStatusTitle = (status) => {
  switch (status) {
    case 0:
      return 'Not Answered'
    case 4:
      return 'Accepted'
    case 5:
      return 'Rejected'
    default:
      return 'No Status'
  }
}

const caseSummaryMap = (response) => {
  const { data } = response
  const customerPhone = data.customer ? data.customer.phone : null

  const customerId = data.customer ? data.customer.id : ''
  const customerName = data.customer ? data.customer.name : ''
  const salesPerson = data.salesPerson ? data.salesPerson.name : ''
  const customerPhoneText = customerPhone
    ? `
    ${customerPhone.slice(0, 2)}
    ${customerPhone.slice(2, 4)}
    ${customerPhone.slice(4, 6)}
    ${customerPhone.slice(6, 8)}
  `
    : ''
  const financedAmount = data.financialInfo
    ? data.financialInfo.financedAmount
    : '-'
  const upfrontAmount = data.financialInfo
    ? data.financialInfo.upfrontAmount
    : '-'
  const vendorName = data.vendor && data.vendor.name ? data.vendor.name : ''
  const vendorOrgNo = data.vendor ? data.vendor.id : ''

  const map = [
    {
      label: 'Customer',
      title: customerName || customerId,
      details: [customerPhoneText],
      process: [
        'document-control',
        'credit-check',
        'source-of-funds-private',
        'customer-declaration-private',
        'source-of-funds-business',
        'customer-declaration-business'
      ]
    },
    {
      label: 'Vendor',
      title: `${vendorName} ${vendorOrgNo}`,
      details: [salesPerson],
      process: ['document-control', 'credit-check']
    },
    {
      label: 'FinancedAmount',
      title: financedAmount.toString(),
      process: [
        'document-control',
        'credit-check',
        'source-of-funds-private',
        'source-of-funds-business'
      ]
    },
    {
      label: 'UpfrontAmount',
      title: upfrontAmount.toString(),
      process: ['source-of-funds-private', 'source-of-funds-business']
    },
    ...(data.coCustomer
      ? [
          {
            label: 'CoCustomer',
            title: data.coCustomer.name || data.coCustomer.id,
            process: ['customer-declaration-private']
          }
        ]
      : [])
  ]

  if (
    data.products &&
    data.products.length > 0 &&
    data.products[0].productName === 'PPI'
  ) {
    return [
      ...map,
      {
        label: 'Payment Insurance',
        title: getStatusTitle(data.products[0].status),
        process: [
          'document-control',
          'credit-check',
          'source-of-funds-private',
          'customer-declaration-private'
        ]
      }
    ]
  }

  return map
}

export default caseSummaryMap
